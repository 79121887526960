import * as HeroIconsSolid from '@heroicons/react/20/solid';
import * as HeroIconsOutline from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Image from 'next/image';

export type LocalIconKey =
  | 'airplane'
  | 'boxes'
  | 'briefcase'
  | 'calendar02'
  | 'campsite'
  | 'carret-up'
  | 'carret-down'
  | 'duplicate'
  | 'check'
  | 'direction-left'
  | 'direction-right'
  | 'edit'
  | 'eye'
  | 'flag'
  | 'home'
  | 'info-cr-fr'
  | 'layers'
  | 'line-down'
  | 'line-up'
  | 'lock'
  | 'megaphone'
  | 'microchip'
  | 'perspective'
  | 'plus-cr-fr'
  | 'plus-sq-fr'
  | 'question-mark-sq-fr'
  | 'recycle'
  | 'reports'
  | 'renewable'
  | 'ruler'
  | 'search'
  | 'search-in-lc'
  | 'settings'
  | 'shop'
  | 'styler'
  | 't-shirt'
  | 'trash-can'
  | 'trolley'
  | 'truck'
  | 'user'
  | 'view-list'
  | 'warning';

type HeroIconKey = keyof typeof HeroIconsSolid;

export type IconType =
  | {
      name: LocalIconKey;
      source: 'local';
      type: 'solid' | 'line';
      text?: never;
      hero?: never;
    }
  | { name: HeroIconKey; source: 'hero'; type: 'solid' | 'line'; text?: never }
  | {
      text: string;
      source?: never;
      name?: never;
      type?: never;
      hero?: never;
    };
type IconProps = {
  icon: IconType;
  width?: number;
  height?: number;
  className?: string;
};
const Icon = ({ icon, width = 20, height = 20, className }: IconProps) => {
  if (icon.source === 'hero') {
    const HeroIconComponent =
      icon.type === 'solid'
        ? HeroIconsSolid[icon.name]
        : HeroIconsOutline[icon.name];
    return (
      <HeroIconComponent
        className={className}
        width={width}
        height={height}
        strokeWidth={1.8} // Make sure we have a consistent stroke width between 24px outline and 20px solid icons
      />
    );
  }
  if (icon.source === 'local') {
    const imagePath = `/img/icons/${icon.type}/${icon.name}.svg`;
    return (
      <Image
        src={imagePath}
        height={height}
        width={width}
        className={classNames(className, 'fill-orange-800')}
        alt={`icon-${icon.name}`}
      />
    );
  }
  return (
    <div className="w-8 h-8 p-1 rounded-md justify-center items-center gap-2 inline-flex">
      <div className="w-6 h-6 text-center text-xl font-medium leading-7">
        {icon.text}
      </div>
    </div>
  );
};

export default Icon;
